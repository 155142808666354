import React from "react";
import useClientOnly from "../../hooks/useClientOnly";

import "./style.scss";

const getTime = () => {
  const date = new Date();
  return {
    hour1Text: Math.floor(date.getHours() / 10),
    hour1: Math.floor(date.getHours() / 10)
      .toString(2)
      .padStart(2, "0"),
    hour0Text: date.getHours() % 10,
    hour0: (date.getHours() % 10).toString(2).padStart(4, "0"),
    min1Text: Math.floor(date.getMinutes() / 10),
    min1: Math.floor(date.getMinutes() / 10)
      .toString(2)
      .padStart(3, "0"),
    min0Text: date.getMinutes() % 10,
    min0: (date.getMinutes() % 10).toString(2).padStart(4, "0"),
    sec1Text: Math.floor(date.getSeconds() / 10),
    sec1: Math.floor(date.getSeconds() / 10)
      .toString(2)
      .padStart(3, "0"),
    sec0Text: date.getSeconds() % 10,
    sec0: (date.getSeconds() % 10).toString(2).padStart(4, "0"),
  };
};

const Clock = () => {
  const [time, setTime] = React.useState(getTime());

  React.useEffect(() => {
    const timer = () => {
      setTime(getTime());
      setTimeout(timer, 1000);
    };
    const timerRef = setTimeout(timer, 1000);
    return () => clearTimeout(timerRef);
  }, []);

  const isRendered = useClientOnly();
  if (!isRendered) {
    return null;
  }

  const formatedDate = new Date();

  return (
    <time
      className="clock-wrapper"
      aria-label="Current time"
      dateTime={formatedDate.toISOString()}
    >
      <div className="clock" role="presentation">
        <div className="clock-section">
          <div className="clock-column">
            <div
              className={`clock-elem ${time.hour1[0] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.hour1[1] === "1" ? "active" : ""}`}
            ></div>
          </div>
          <div className="clock-column">
            <div
              className={`clock-elem ${time.hour0[0] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.hour0[1] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.hour0[2] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.hour0[3] === "1" ? "active" : ""}`}
            ></div>
          </div>
        </div>
        <div className="clock-section">
          <div className="clock-column">
            <div
              className={`clock-elem ${time.min1[0] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.min1[1] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.min1[2] === "1" ? "active" : ""}`}
            ></div>
          </div>
          <div className="clock-column">
            <div
              className={`clock-elem ${time.min0[0] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.min0[1] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.min0[2] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.min0[3] === "1" ? "active" : ""}`}
            ></div>
          </div>
        </div>
        <div className="clock-section">
          <div className="clock-column">
            <div
              className={`clock-elem ${time.sec1[0] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.sec1[1] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.sec1[2] === "1" ? "active" : ""}`}
            ></div>
          </div>
          <div className="clock-column">
            <div
              className={`clock-elem ${time.sec0[0] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.sec0[1] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.sec0[2] === "1" ? "active" : ""}`}
            ></div>
            <div
              className={`clock-elem ${time.sec0[3] === "1" ? "active" : ""}`}
            ></div>
          </div>
        </div>
      </div>
      <div className="clock clock-text" role="presentation">
        <div className="clock-section">
          <div className="clock-column">{time.hour1Text}</div>
          <div className="clock-column">{time.hour0Text}</div>
        </div>
        <div className="clock-section">
          <div className="clock-column">{time.min1Text}</div>
          <div className="clock-column">{time.min0Text}</div>
        </div>
        <div className="clock-section">
          <div className="clock-column">{time.sec1Text}</div>
          <div className="clock-column">{time.sec0Text}</div>
        </div>
      </div>
    </time>
  );
};

export default Clock;
