module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"quentin-golsteyn","short_name":"qg","start_url":"/","background_color":"#FFFFFF","theme_color":"rgb(34, 61, 125)","display":"minimal-ui","icon":"content/image/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"18f55c15d486de9d6bd254a09292af40"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
