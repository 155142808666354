// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-blog-beyond-js": () => import("./../../../content/blog/beyond.js" /* webpackChunkName: "component---content-blog-beyond-js" */),
  "component---content-blog-cds-js": () => import("./../../../content/blog/cds.js" /* webpackChunkName: "component---content-blog-cds-js" */),
  "component---content-blog-corner-kick-js": () => import("./../../../content/blog/corner-kick.js" /* webpackChunkName: "component---content-blog-corner-kick-js" */),
  "component---content-blog-designing-keyboard-shortcuts-js": () => import("./../../../content/blog/designing-keyboard-shortcuts.js" /* webpackChunkName: "component---content-blog-designing-keyboard-shortcuts-js" */),
  "component---content-blog-dice-js": () => import("./../../../content/blog/dice.js" /* webpackChunkName: "component---content-blog-dice-js" */),
  "component---content-blog-easy-as-pi-js": () => import("./../../../content/blog/easy-as-pi.js" /* webpackChunkName: "component---content-blog-easy-as-pi-js" */),
  "component---content-blog-pharm-drone-js": () => import("./../../../content/blog/pharm-drone.js" /* webpackChunkName: "component---content-blog-pharm-drone-js" */),
  "component---content-blog-sudoku-js": () => import("./../../../content/blog/sudoku.js" /* webpackChunkName: "component---content-blog-sudoku-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-analytics-js": () => import("./../../../src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-private-newsletter-21-js": () => import("./../../../src/pages/private/newsletter21.js" /* webpackChunkName: "component---src-pages-private-newsletter-21-js" */)
}

