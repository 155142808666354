import { Link } from "gatsby";
import React from "react";

import "./styles.scss";

const FooterNav = () => {
  return (
    <nav className="footer-nav">
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/analytics">Analytics</Link>
    </nav>
  );
};

export default FooterNav;
