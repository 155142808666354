import React from "react"

const useClientOnly = () => {
  const [isRendered, setIsRendered] = React.useState(false)

  React.useEffect(() => {
    setIsRendered(true)
  }, [])

  return isRendered
}

export default useClientOnly
