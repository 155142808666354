import React from "react";
import { Link } from "gatsby";

import "./index.scss";
import { AnimatePresence, motion } from "framer-motion";
import logo from "../../../content/image/logo_large.png";

export const NavContext = React.createContext({
  setNav: () => {},
});

const Nav = ({ children, hideNav }) => {
  const [currentNav, setCurrentNav] = React.useState([]);

  const blurEventTimeout = React.useRef(undefined);

  return (
    <NavContext.Provider
      value={{
        setNav: async (newNav) => {
          setCurrentNav(newNav);
        },
      }}
    >
      <nav className="nav">
        <div className="nav__logo-container--mobile">
          <Link className="logo" to="/">
            <img src={logo} alt="" draggable={false} />
          </Link>
        </div>
        <div className="nav__breadcrumb-container">
          <div>
            <Link className="logo" to="/" draggable={false}>
              <img src={logo} alt="" draggable={false} />
            </Link>
          </div>
          <AnimatePresence exitBeforeEnter>
            {currentNav.map((item, index) => (
              <motion.span
                key={index + item.name}
                initial={{ translateY: "-10%", opacity: 0 }}
                animate={{ translateY: "0%", opacity: 1 }}
                exit={{
                  translateY: "10%",
                  opacity: 0,
                  transition: {
                    delay:
                      ((currentNav.length - index - 1) / currentNav.length) *
                      0.4,
                    ease: "easeIn",
                  },
                }}
                transition={{ duration: 0.4, ease: "easeOut" }}
              >
                <b className="nav__breadcrumb-container--arrow">›</b>
                <Link className="button" to={item.to} draggable={false}>
                  {item.name}
                </Link>
              </motion.span>
            ))}
          </AnimatePresence>
        </div>
        {!hideNav && (
          <div className="nav__link-container">
            <Link
              className="button nav__link-container--button"
              to="/about"
              draggable={false}
            >
              About
            </Link>
          </div>
        )}
      </nav>

      {children}
    </NavContext.Provider>
  );
};

export default Nav;
