import pages from "../../content/pages.json";

const sessionData = {
  session_id: undefined,
  currentPage: undefined,
  lastUserAction: Date.now(),
};

document.addEventListener("scroll", () => {
  if (
    sessionData.currentPage?.location &&
    sessionData.lastUserAction < Date.now() - 4 * 60 * 1000
  ) {
    createPageview(sessionData.currentPage.location);
  }
  sessionData.lastUserAction = Date.now();
});
document.addEventListener("mousemove", () => {
  if (
    sessionData.currentPage?.location &&
    sessionData.lastUserAction < Date.now() - 4 * 60 * 1000
  ) {
    createPageview(sessionData.currentPage.location);
  }
  sessionData.lastUserAction = Date.now();
});
document.addEventListener("pointermove", () => {
  if (
    sessionData.currentPage?.location &&
    sessionData.lastUserAction < Date.now() - 4 * 60 * 1000
  ) {
    createPageview(sessionData.currentPage.location);
  }
  sessionData.lastUserAction = Date.now();
});
document.addEventListener("click", () => {
  if (
    sessionData.currentPage?.location &&
    sessionData.lastUserAction < Date.now() - 4 * 60 * 1000
  ) {
    createPageview(sessionData.currentPage.location);
  }
  sessionData.lastUserAction = Date.now();
});
document.addEventListener("keypress", () => {
  if (
    sessionData.currentPage?.location &&
    sessionData.lastUserAction < Date.now() - 4 * 60 * 1000
  ) {
    createPageview(sessionData.currentPage.location);
  }
  sessionData.lastUserAction = Date.now();
});

const getScrollPercent = () => {
  const h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight";
  return Math.round(
    ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
  );
};

const pingPageview = (pageview_id) => () => {
  if (sessionData.lastUserAction >= Date.now() - 30 * 1000) {
    const maxScroll = sessionData.currentPage.maxScroll || 0;
    const currentScroll = getScrollPercent();

    if (currentScroll > maxScroll) {
      sessionData.currentPage.maxScroll = currentScroll;
    }

    fetch("/.netlify/functions/pingPageview", {
      method: "POST",
      body: JSON.stringify({
        pageview_id,
        max_scroll: sessionData.currentPage.maxScroll,
      }),
    });
  }
};

export const createPageview = async (location) => {
  if (localStorage.getItem("disableAnalytics") === "true") {
    return;
  }

  if (sessionData.currentPage) {
    sessionData.currentPage.pingPageview();
    clearInterval(sessionData.currentPage.pingInterval);
  }

  if (pages.includes(location.pathname.replace(/(?:.+)\/$/, "") || "/")) {
    const result = await fetch("/.netlify/functions/trackPageview", {
      method: "POST",
      body: JSON.stringify({
        path: location.pathname,
        session_id: sessionData.session_id,
      }),
    });

    const { pageview_id, session_id } = await result.json();

    sessionData.session_id = session_id;

    const pingPageviewFn = pingPageview(pageview_id);

    sessionData.currentPage = {
      location,
      scroll: 0,
      pingPageview: pingPageviewFn,
      pingInterval: setInterval(pingPageviewFn, 15 * 1000),
    };
  } else {
    sessionData.currentPage = undefined;
  }
};

window.disableAnalytics = () => {
  localStorage.setItem("disableAnalytics", "true");
};
